import React from 'react'
import styled from 'styled-components'
import { FlexDiv } from '../design-system/FlexDiv'
import aurora from '../images/northYorkCenter.jpg'

const Pages = styled.h1`
  font-family: CentraNo2-Book;
  font-size: 45px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  // line-height: 2.44;
  letter-spacing: -0.68px;
  text-align: left;
  color: #ffffff;
  margin-right: 16px;
`

const Rectangle = styled.div`
  width: 27.5em;
  height: 25em;
  opacity: 0.9;
  background-color: #1c1c1c;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 150px;
  display: flex;
  flex-direction: column;
  padding: 0 35px;
`

const Info = styled.div`
  margin: 0px;
  color: white;
  display: flex;
  // background: red;
  justify-content: space-between;
`

const NoPages = styled.h1`
  font-family: CentraNo2-thin;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  // line-height: 4.58;
  letter-spacing: -0.36px;
  text-align: left;
  color: #adadad;
`

const Arrows = styled.div`
  margin-top: 10%;
  // margin-left: 50px;
`

const OurHistoryWrapper = styled.div`
  max-width: 90%;
  margin: auto;
  padding: 2rem 5rem;
  display: inline-flex;
`

const OurHistoryTitle = styled.h3`
  font-family: CentraNo2-Bold;
`

const OurHistoryText = styled.p`
  font-family: CentraNo2-Book;
  font-size: 0.9em;
  line-height: 1.5;
`

export const NorthYorkCommunityInformation: React.FunctionComponent = () => {
  return (
    <OurHistoryWrapper className="ourHistoryWrapper">
      <FlexDiv style={{ flexFlow: 'column' }}>
        <FlexDiv className="informationImgWrapper">
          <FlexDiv style={{ flex: 1 }}>
            <div>
              <img
                src={aurora}
                style={{ width: '90%', height: '90%', objectFit: 'contain' }}
              />
            </div>
          </FlexDiv>
          <FlexDiv
            style={{
              flex: 2,
              flexDirection: 'column',
              placeSelf: 'flex-start',
            }}
          >
            <OurHistoryTitle style={{ marginTop: 0 }}>
              Education & Government
            </OurHistoryTitle>
            <FlexDiv>
              <OurHistoryText>
                Four public school boards operate primary and secondary
                institutions in North York, Conseil scolaire catholique
                MonAvenir (CSCM), Conseil scolaire Viamonde (CSV), the Toronto
                Catholic District School Board (TCDSB), and the Toronto District
                School Board (TDSB). CSV and TDSB operate as secular public
                school boards, the former operating French first language
                institution, whereas the latter operated English first language
                institutions. The other two school boards, CSCM and TCDSB,
                operate as public separate school boards, the former operating
                French first language separate schools, the latter operating
                English first language separate schools. All four Toronto-based
                public school boards are headquartered within North York. Prior
                to 1998, the North York Board of Education and Conseil des
                écoles françaises de la communauté urbaine de Toronto operated
                English and French public secular schools, while the
                Metropolitan Separate School Board operated English and French
                public separate schools for North York pupils.
              </OurHistoryText>
            </FlexDiv>
            <OurHistoryText style={{ flex: 1 }}>
              In addition to primary and secondary schools, several
              post-secondary institutions were established in North York. York
              University is a university that was established in 1959. The
              university operates two campuses in North York, the Keele campus
              located in the north, and Glendon College, a bilingual campus
              operated by the university. There are also two colleges that
              operate campuses in North York. Seneca College was established in
              North York in 1967, and presently operates several campuses
              throughout North York, and Greater Toronto. One of Centennial
              College's campuses are also located in North York, known as the
              Downsview Park Aerospace Campus.
            </OurHistoryText>
          </FlexDiv>
        </FlexDiv>
      </FlexDiv>
    </OurHistoryWrapper>
  )
}
