import React from 'react'
import { FlexDiv } from '../design-system/FlexDiv'
import styled from 'styled-components'
import GraduationHatIcon from '../assets/icons/graduation-hat.svg'
import { GoogleMap, MarkerType } from './GoogleMap'
import { SearchBar } from './SearchBar'
import { PlaceType } from '@services/google-maps/google-maps-services.type'
import { services } from '@services'
import { GetListingsOptionsType } from '@services/repliers/get-listings.service'

import FoodIcon from '../assets/icons/food.svg'
import TreeIcon from '../assets/icons/pine-tree.svg'
import BankIcon from '../assets/icons/museum.svg'
import ShoeIcon from '../assets/icons/shoes.svg'
import BusIcon from '../assets/icons/bus.svg'

const ResultP = styled.p`
  font-family: 'CentraNo2-Medium';
  -webkit-font-smoothing: antialiased;
`

export const CommunitySearch: React.FC = () => {
  const [searchOptions, setSearchOptions] = React.useState<
    GetListingsOptionsType
  >()

  const startingPosition = { lat: 43.761539, lng: -79.411079 }
  const [placesResults, setPlacesResults] = React.useState<
    google.maps.places.PlaceResult[]
  >()
  const [currentPosition, setCurrentPosition] = React.useState<
    google.maps.LatLng
  >(new google.maps.LatLng(startingPosition))
  const [selectedType, setSelectedType] = React.useState<PlaceType>('school')
  const [mapRef, setMapRef] = React.useState<google.maps.Map>()
  const [placesMarkers, setPlacesMarkers] = React.useState<MarkerType[]>([])
  const [listingMarkers, setListingMarkers] = React.useState<MarkerType[]>([])
  const [allMarkers, setAllMarkers] = React.useState<MarkerType[]>([])

  React.useEffect(() => {
    if (currentPosition && selectedType && mapRef) {
      handlePlacesSearch(currentPosition, selectedType)
    }
  }, [currentPosition, selectedType, mapRef])

  React.useEffect(() => {
    setAllMarkers(placesMarkers.concat(listingMarkers))
  }, [placesMarkers, listingMarkers])

  React.useEffect(() => {
    if (searchOptions?.searchString) {
      services.repliers.getListings({ ...searchOptions }).then(response => {
        setListingMarkers(
          (response.data || [])
            .filter(p => p && p.map)
            .map(p =>
              p && p.map
                ? {
                    location: new google.maps.LatLng(
                      parseFloat(p.map.latitude),
                      parseFloat(p.map.longitude),
                    ),
                    place: false,
                  }
                : { location: new google.maps.LatLng(0, 0), place: false },
            ),
        )
      })
    }
  }, [searchOptions])

  const handlePlacesSearch = (
    position: google.maps.LatLng,
    type: PlaceType,
  ) => {
    if (mapRef) {
      services.gmaps
        .getPlaces({ map: mapRef, position, type })
        .then(response => {
          setPlacesMarkers(
            (response.data || [])
              .filter(p => p && p.geometry && p.geometry.location)
              .map(p =>
                p && p.geometry && p.geometry.location
                  ? {
                      location: new google.maps.LatLng(
                        p.geometry.location.lat(),
                        p.geometry.location.lng(),
                      ),
                      place: true,
                    }
                  : { location: new google.maps.LatLng(0, 0), place: true },
              ),
          )
          setPlacesResults(response.data)
        })
    }
  }

  return (
    <div style={{ background: '#F5F5F5' }} className="listingsSec">
      <div
        style={{
          maxWidth: '90%',
          margin: 'auto',
          padding: '2em 0 5em',
        }}
      >
        <SearchBar
          defaultSearchString="Toronto"
          handleSearch={setSearchOptions}
        />
        <FlexDiv
          container
          justify="space-between"
          style={{ height: '50vh' }}
          className="CommunitySearchContainer"
        >
          <FlexDiv
            style={{ background: '#1C1C1C', color: 'white' }}
            className="CommunitySearchBarWrapper"
          >
            <FlexDiv
              vert
              style={{ flex: '0.2' }}
              className="CommunitySearchBarWrapper2"
            >
              <FlexDiv
                justify="center"
                align="center"
                style={{
                  width: '100%',
                  borderTop: '1px solid gray',
                  textAlign: 'center',
                  background: 'rgb(28 28 28)',
                  padding: '5px 0',
                }}
                className="resultsIconWrapper"
              >
                <div onClick={() => setSelectedType('school')}>
                  <img src={GraduationHatIcon} style={{ width: '35%' }} />
                  <p
                    style={{
                      margin: 0,
                      fontSize: 12,
                      fontFamily: 'CentraNo2-Light',
                    }}
                  >
                    Schools
                  </p>
                </div>
              </FlexDiv>
              <FlexDiv
                justify="center"
                align="center"
                style={{
                  width: '100%',
                  borderTop: '1px solid gray',
                  textAlign: 'center',
                  background: 'rgb(28 28 28)',
                  padding: '5px 0',
                }}
                className="resultsIconWrapper"
              >
                <div onClick={() => setSelectedType('restaurant')}>
                  <img src={FoodIcon} style={{ width: '35%' }} />
                  <p
                    style={{
                      margin: 0,
                      fontSize: 12,
                      fontFamily: 'CentraNo2-Light',
                    }}
                  >
                    Restaurants
                  </p>
                </div>
              </FlexDiv>
              <FlexDiv
                justify="center"
                align="center"
                style={{
                  width: '100%',
                  borderTop: '1px solid gray',
                  textAlign: 'center',
                  background: 'rgb(28 28 28)',
                  padding: '5px 0',
                }}
                className="resultsIconWrapper"
              >
                <div onClick={() => setSelectedType('park')}>
                  <img src={TreeIcon} style={{ width: '35%' }} />
                  <p
                    style={{
                      margin: 0,
                      fontSize: 12,
                      fontFamily: 'CentraNo2-Light',
                    }}
                  >
                    Parks
                  </p>
                </div>
              </FlexDiv>
              <FlexDiv
                justify="center"
                align="center"
                style={{
                  width: '100%',
                  borderTop: '1px solid gray',
                  textAlign: 'center',
                  background: 'rgb(28 28 28)',
                  padding: '5px 0',
                }}
                className="resultsIconWrapper"
              >
                <div onClick={() => setSelectedType('bank')}>
                  <img src={BankIcon} style={{ width: '35%' }} />
                  <p
                    style={{
                      margin: 0,
                      fontSize: 12,
                      fontFamily: 'CentraNo2-Light',
                    }}
                  >
                    Banks
                  </p>
                </div>
              </FlexDiv>
              <FlexDiv
                justify="center"
                align="center"
                style={{
                  width: '100%',
                  borderTop: '1px solid gray',
                  textAlign: 'center',
                  background: 'rgb(28 28 28)',
                  padding: '5px 0',
                }}
                className="resultsIconWrapper"
              >
                <div onClick={() => setSelectedType('gym')}>
                  <img src={ShoeIcon} style={{ width: '35%' }} />
                  <p
                    style={{
                      margin: 0,
                      fontSize: 12,
                      fontFamily: 'CentraNo2-Light',
                    }}
                  >
                    Fitness
                  </p>
                </div>
              </FlexDiv>
              <FlexDiv
                justify="center"
                align="center"
                style={{
                  width: '100%',
                  borderTop: '1px solid gray',
                  textAlign: 'center',
                  background: 'rgb(28 28 28)',
                  padding: '5px 0',
                }}
                className="resultsIconWrapper"
              >
                <div onClick={() => setSelectedType('transit_station')}>
                  <img src={BusIcon} style={{ width: '35%' }} />
                  <p
                    style={{
                      margin: 0,
                      fontSize: 12,
                      fontFamily: 'CentraNo2-Light',
                    }}
                  >
                    Transit
                  </p>
                </div>
              </FlexDiv>
            </FlexDiv>
            <FlexDiv
              size={4}
              vert
              style={{
                background: 'white',
                color: 'black',
                overflow: 'hidden',
                maxHeight: '100%',
              }}
              className="resultsWrapper"
            >
              {placesResults &&
                placesResults.map(result => (
                  <FlexDiv
                    key={result.id}
                    align="center"
                    style={{
                      width: '100%',
                      borderTop: '1px solid gray',
                      textAlign: 'center',
                      padding: '10px 0px 10px 20px',
                    }}
                    className="communityData"
                  >
                    <div
                      style={{
                        background: 'black',
                        color: 'white',
                        borderRadius: '100%',
                        width: 25,
                        height: 25,
                      }}
                      className="communityBall"
                    >
                      <p style={{ marginTop: 5, fontSize: 12 }}>1</p>
                    </div>

                    <ResultP
                      style={{
                        margin: 0,
                        marginLeft: 20,
                        fontSize: '0.9em',
                        fontFamily: 'CentraNo2-Book',
                      }}
                    >
                      {result.name}
                    </ResultP>
                  </FlexDiv>
                ))}
            </FlexDiv>
          </FlexDiv>
          <FlexDiv
            size={2}
            style={{ overflow: 'hidden', width: '200', height: '200' }}
            className="gmapWrapper"
          >
            <GoogleMap
              zoom={12}
              center={currentPosition}
              onCenterChanged={() => {
                // console.log('onCenterChanged', r)
                if (mapRef) {
                  const newPos = mapRef.getCenter()
                  if (
                    currentPosition.lat() !== newPos.lat() &&
                    currentPosition.lng() !== newPos.lng()
                  ) {
                    setCurrentPosition(newPos)
                  }
                }
              }}
              markers={allMarkers}
              onLoad={ref => setMapRef(ref)}
            />
          </FlexDiv>
        </FlexDiv>
      </div>
    </div>
  )
}
