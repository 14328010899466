import React from 'react'
import styled from 'styled-components'
import { FlexDiv } from '../design-system/FlexDiv'

const Pages = styled.h1`
  font-family: CentraNo2-Book;
  font-size: 45px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  // line-height: 2.44;
  letter-spacing: -0.68px;
  text-align: left;
  color: #ffffff;
  margin-right: 16px;
`

const Rectangle = styled.div`
  width: 27.5em;
  height: 25em;
  opacity: 0.9;
  background-color: #1c1c1c;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 150px;
  display: flex;
  flex-direction: column;
  padding: 0 35px;
`

const Info = styled.div`
  margin: 0px;
  color: white;
  display: flex;
  // background: red;
  justify-content: space-between;
`

const NoPages = styled.h1`
  font-family: CentraNo2-thin;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  // line-height: 4.58;
  letter-spacing: -0.36px;
  text-align: left;
  color: #adadad;
`

const Arrows = styled.div`
  margin-top: 10%;
  // margin-left: 50px;
`

const OurHistoryWrapper = styled.div`
  max-width: 90%;
  margin: auto;
  padding: 3rem 5rem 2rem;
  display: inline-flex;
`

const OurHistoryTitle = styled.h3`
  font-family: CentraNo2-Bold;
  margin: 0 1em;
`

const OurHistoryText = styled.p`
  font-family: CentraNo2-Book;
  font-size: 0.9em;
  line-height: 1.5em;
  margin: 2em 1em;
`

export const NorthYorkCommunityGeo: React.FunctionComponent = () => {
  return (
    <OurHistoryWrapper className="commGeoWrapper">
      <FlexDiv style={{ flexFlow: 'column' }}>
        <FlexDiv>
          <OurHistoryTitle>Economy</OurHistoryTitle>
        </FlexDiv>
        <FlexDiv className="mobileColumn">
          <OurHistoryText style={{ flex: 1 }}>
            The district's central business district is known as North York
            Centre, which was the location of the former city's government and
            major corporate headquarters. North York Centre continues to be one
            of Toronto's major corporate areas with many office buildings and
            businesses. The former city hall of North York, the North York Civic
            Centre, is located within North York City Centre.
          </OurHistoryText>
          <OurHistoryText style={{ flex: 1 }}>
            Flemingdon Park, located near Eglinton and Don Mills, is an economic
            hub located near the busy Don Valley Parkway and busy Toronto
            Transit Commission (TTC) routes. McDonald's Canada and Celestica are
            located in this area, and Foresters Insurance has a major office
            tower and Bell Canada has a data centre.
          </OurHistoryText>
          <OurHistoryText style={{ flex: 1 }}>
            North York houses two of Toronto's five major shopping malls: the
            Yorkdale Shopping Centre and Fairview Mall. Other neighbourhood
            malls locations include Centerpoint Mall, Bayview Village, Sheridan
            Mall, Yorkgate Mall, Shops at Don Mills, Steeles West Market Mall,
            Jane Finch Mall and Sheppard Centre.
          </OurHistoryText>
        </FlexDiv>
      </FlexDiv>
    </OurHistoryWrapper>
  )
}
