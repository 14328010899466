import React from 'react'
import styled from 'styled-components'
import { FlexDiv } from '../design-system/FlexDiv'

const Pages = styled.h1`
  font-family: CentraNo2-Book;
  font-size: 45px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  // line-height: 2.44;
  letter-spacing: -0.68px;
  text-align: left;
  color: #ffffff;
  margin-right: 16px;
`

const Rectangle = styled.div`
  width: 27.5em;
  height: 25em;
  opacity: 0.9;
  background-color: #1c1c1c;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 150px;
  display: flex;
  flex-direction: column;
  padding: 0 35px;
`

const Info = styled.div`
  margin: 0px;
  color: white;
  display: flex;
  // background: red;
  justify-content: space-between;
`

const NoPages = styled.h1`
  font-family: CentraNo2-thin;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  // line-height: 4.58;
  letter-spacing: -0.36px;
  text-align: left;
  color: #adadad;
`

const Arrows = styled.div`
  margin-top: 10%;
  // margin-left: 50px;
`

const OurHistoryWrapper = styled.div`
  max-width: 90%;
  margin: auto;
  padding: 5rem 5rem 2rem;
  display: inline-flex;
`

const OurHistoryTitle = styled.h3`
  font-family: CentraNo2-Bold;
  margin: 0 1em;
`

const OurHistoryText = styled.p`
  font-family: CentraNo2-Book;
  font-size: 0.9em;
  line-height: 1.5em;
  margin: 2em 1em;
`

export const NorthYorkCommunityHistory: React.FunctionComponent = () => {
  return (
    <OurHistoryWrapper className="ourHistoryWrapper">
      <FlexDiv style={{ flexFlow: 'column' }}>
        <FlexDiv>
          <OurHistoryTitle>Histortical Background</OurHistoryTitle>
        </FlexDiv>
        <FlexDiv className="mobileColumn">
          <OurHistoryText style={{ flex: 1 }}>
            The Township of North York was formed on June 13, 1922 out of the
            rural part of the Township of York. The rapidly growing parts of the
            township remained in that township. As North York became more
            populous, it became the Borough of North York in 1967, and then on
            February 14, 1979, the City of North York. To commemorate receiving
            its city charter on Valentine's Day, the city's corporate slogan was
            "The City with Heart". It now forms the largest part of the area
            served by the "North York Community Council", a committee of Toronto
            City Council.
          </OurHistoryText>
          <OurHistoryText style={{ flex: 1 }}>
            The expansion of Toronto into a metropolitan centre had considerable
            impact on North York, which was transformed in the 1950s and 1960s
            into a suburban community and an integral part of Metropolitan
            Toronto. North York was a city (incorporated from 1979 to 1998)
            until it was amalgamated into the new megacity of TORONTO in 1998.
            The name North York refers to both Toronto's original name, York,
            and the township, and later the former city's location north of
            Toronto proper. American suburbs.
          </OurHistoryText>
          <OurHistoryText style={{ flex: 1 }}>
            As its population increased its municipal status rose to borough in
            1967 and later to city in 1979. North York is home to a number of
            significant institutions including YORK UNIVERSITY, Seneca College
            of Applied Arts and Technology, the Ontario Science Centre, BLACK
            CREEK PIONEER VILLAGE and the Ford Centre for the Performing Arts.
            It is also home to renowned hospitals and prior to amalgamation with
            Toronto, had the second-largest manufacturing base in Ontario.
          </OurHistoryText>
        </FlexDiv>
      </FlexDiv>
    </OurHistoryWrapper>
  )
}
