import React from 'react'
import { EllicotLayout } from 'components/EllicotLayout'
import { NorthYorkCommunityHistory } from 'components/NorthYorkCommunityHistory'
import { NorthYorkCommunityGeo } from 'components/NorthYorkCommunityGeo'
import { OurPromise } from 'components/OurPromise'
import { GivesBack } from 'components/GivesBack'
import { NorthYorkCommunityHero } from 'components/NorthYorkCommunityHero'
import { NorthYorkCommunityInformation } from 'components/NorthYorkCommunityInformation'
import { CommunitySearch } from 'components/CommunitySearchNorthYork'
import { GetListingsOptionsType } from '@services/repliers/get-listings.service'
import { GetListingsType } from '@services/repliers/repliers-services.type'
import { services } from '@services'

const NorthYork: React.FunctionComponent = () => {
  return (
    <EllicotLayout>
      <NorthYorkCommunityHero />
      <NorthYorkCommunityHistory />
      <NorthYorkCommunityInformation />
      <NorthYorkCommunityGeo />
      {/* change to north york when u figure out why it doesnt work */}
      <CommunitySearch />
    </EllicotLayout>
  )
}

export default NorthYork
